import React from 'react'

const Contact = () => {

  return (
    <div id="contact">
        <div className='row'>
            <div className='col l12 m12 s12'>
                <div id="contact-content">
                    <h2 id="contact-heading">We're happy to hear from you!</h2>
                    <p id='contact-intramural'><b>INTRAMURAL:</b> 28lgn28@gmail.com</p>
                    <p id='contact-travel'><b>TRAVEL:</b> fstagnittasoccer@gmail.com</p>
                </div>
            </div>
            {/* <div className='col l4 m12 s12'>
                <div id="facebook-container">
                    <div className="fb-page" data-href="https://www.facebook.com/LGNsoccer/" data-tabs="timeline" data-width="" data-height="" data-small-header="true" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="false"><blockquote cite="https://www.facebook.com/LGNsoccer/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/LGNsoccer/">LGN Lake Grove-Newfield Soccer Club</a></blockquote></div>
                </div>
            </div> */}
        </div>
    </div>
  )
}

export default Contact