import React from 'react'

const SocialMedia = () => {


  return (
    <>
        <div id="icon-facebook-div"><a href="https://www.facebook.com/LGNsoccer" id="icon-facebook-anchor" target="_blank" rel="noreferrer"><ion-icon name="logo-facebook" className="icon"></ion-icon></a></div>
        <div id="icon-instagram-div"><a href="https://www.instagram.com/lgn_soccer/" id="icon-instagram-anchor" target="_blank" rel="noreferrer"><ion-icon name="logo-instagram" className="icon"></ion-icon></a></div>
    </>
  )
}

export default SocialMedia